<template>
  <!-- <van-pull-refresh :head-height="50" v-model="refreshing" @refresh="onRefresh"> -->
    <div id="setting">
      <van-sticky :offset-top="0">
      <div class="header">
        <van-row gutter="10">
          <van-col span="10" class="header-title" @click="onClickLeft">
            <svg-icon
              :data_iconName="'back-default'"
              :className="'back_icon'"
            />编辑
          </van-col>
          <van-col
            span="14"
            class="header-title"
            style="text-align: right"
            @click="onUpdateClick"
          >
            确认修改
          </van-col>
        </van-row>
      </div>
      </van-sticky>
      <div class="setting-container">
        <div class="setting-body">
          <van-cell center :border="true" value="头像" size="large" is-link>
            <template #right-icon>
              <van-uploader
                max-count="1"
                
                accept="image/*"
                :after-read="fnUploadRequest"
              >
                <van-image width="40" height="40" round :src="user.avatar" />
              </van-uploader>
              <van-icon name="arrow" />
            </template>
          </van-cell>
          <!-- <van-cell
              disabled
               :clickable="false"
              center
              :border="true"
              value="旧密码"
              size="large"
              is-link
            >
              <template #right-icon>
                <input
                  class="cell-input"
                  v-model="user.old_password"
                  type="password"
                  placeholder="请输入旧密码"
                />
                <van-icon name="arrow" />
              </template>
            </van-cell> -->
          <!-- <van-cell
              disabled
               :clickable="false"
              center
              :border="true"
              value="新密码"
              size="large"
              is-link
            >
              <template #right-icon>
                <input
                  class="cell-input"
                  v-model="user.password"
                  type="password"
                  placeholder="请输入新密码"
                />
                <van-icon name="arrow" />
              </template>
            </van-cell> -->
          <!-- <van-cell
               :clickable="false"
              center
              :border="true"
              value="确认密码"
              size="large"
              is-link
            >
              <template #right-icon>
                <input
                  class="cell-input"
                  v-model="repassword"
                  type="password"
                  placeholder="请输入确认密码"
                />
                <van-icon name="arrow" />
              </template>
            </van-cell> -->
          <van-cell
            :clickable="false"
            disabled
            center
            :border="true"
            value="用户名称"
            size="large"
            is-link
          >
            <template #right-icon>
              <input
                class="cell-input"
                v-model="user.username"
                type="text"
                placeholder="请输入用户名称"
              />
              <van-icon name="arrow" />
            </template>
          </van-cell>
          <van-cell
            :clickable="false"
            center
            :border="true"
            value="登录手机号"
            size="large"
            is-link
            style="color: #969799"
          >
            <template #right-icon>
              <span style="color: #969799">{{
               mobile
              }}</span>
              <van-icon style="color: #969799" name="arrow" />
            </template>
          </van-cell>
          <van-cell
            :clickable="false"
            center
            :border="true"
            value="文旅局名称"
            size="large"
            is-link
          >
            <template #right-icon>
              <input
                class="cell-input"
                v-model="boss.name"
                type="text"
                placeholder="请输入文旅局名称"
              />
              <van-icon name="arrow" />
            </template>
          </van-cell>
          <van-cell
            :clickable="false"
            center
            :border="true"
            value="地区"
            size="large"
            is-link
            style="color: #969799"
          >
            <template #right-icon>
              <span style="color: #969799">{{
                province_name + "/" + city_name + "/" + district_name
              }}</span>
              <van-icon style="color: #969799" name="arrow" />
            </template>
          </van-cell>
          <van-cell
            :clickable="false"
            center
            :border="true"
            value="地址"
            size="large"
            is-link
          >
            <template #right-icon>
              <input
                class="cell-input"
                v-model="boss.address"
                type="text"
                placeholder="请输入地址"
              />
              <van-icon name="arrow" />
            </template>
          </van-cell>
          <van-cell
            :clickable="true"
            center
            :border="true"
            value="修改密码"
            size="large"
            is-link
            to="/setting/update-password"
          >
            <template #right-icon>
              <van-icon name="arrow" />
            </template>
          </van-cell>
          <van-cell
            @click="onSubUsersClick"
            :clickable="false"
            center
            :border="true"
            value="管理子账号"
            size="large"
            is-link
          >
            <template #right-icon>
              <van-icon name="arrow" />
            </template>
          </van-cell>
          <div class="btn" @click="onUpdateClick">确认修改</div>
        </div>
      </div>
    </div>
  <!-- </van-pull-refresh> -->
</template>

<script>
import cookies from "../../utils/cookies";
import oss from "@/utils/oss";
import { Toast } from "vant";
export default {
  name: "setting-item",
  data() {
    return {
      refreshing: false,
      loading: true,
      user: {
        username: "",
        avatar: "",
        mobile: "",
      },
      repassword: null,
      district_name: "",
      city_name: "",
      province_name: "",
      boss: {
        name: "",
        address: "",
      },
      mobile:""
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    onSubUsersClick() {
      this.$router.push("/setting/sub-users");
    },
    async onUpdateClick() {
      // if (this.user.password !=this.user.password_confirmation) {
      //   Toast("两次密码不一致");
      //   return false;
      // }
      // if(this.user.password!=null&&0<this.user.password.length<6) {
      //   Toast("密码长度必须大于6位");
      //   return false;
      // }
      // if(this.user.password_confirmation!=null&&0<this.user.password_confirmation.length<6) {
      //   Toast("密码长度必须大于6位");
      //   return false;
      // }
      if (!this.user.username) {
        Toast("请填写用户名称");
        return false;
      }
      if (this.user.username.length > 10) {
        Toast("用户名称太长");
        return false;
      }
      if (!this.boss.name) {
        Toast("请填写文旅局名称");
        return false;
      }
      if (this.boss.name.length > 10) {
        Toast("文旅局名称太长");
        return false;
      }

      if (!this.boss.address) {
        Toast("请填写地址");
        return false;
      }
      // delete this.boss.province_name
      // delete this.boss.city_name
      // delete this.boss.district_name
      const res = await this.$apis.update_user({
        user: this.user,
        tourist_office: this.boss,
      });
      if (res.status == 200) {
        Toast("修改成功！");
        this.$router.push("/");
        this.onRefresh();
      }
      cookies.set("user", this.user);
    },
    async get_user_info() {
      const res = await this.$apis.get_user_info();
      this.user.mobile = res.data.user.mobile;
      this.mobile = res.data.user.mobile
      this.user.avatar = res.data.user.avatar;
      this.user.username = res.data.user.username;
      this.boss.name = res.data.boss.name;
      this.boss.address = res.data.boss.address;
      this.province_name = res.data.boss.province_name;
      this.city_name = res.data.boss.city_name;
      this.district_name = res.data.boss.district_name;
      this.loading = false;
      this.refreshing = false;
    },
    fnUploadRequest(option) {
      const _self = this;
      Toast.loading({
        message: "上传中...",
        forbidClick: true,
      });
      oss.ossUploadFile(option).then((res) => {
        _self.user.avatar = res.fileUrl[0].split("?")[0];
        Toast.clear();
      });
    },
    onRefresh() {
      this.finished = false;
      this.loading = true;
      this.get_user_info();
    },
  },
  components: {},
  created() {
    this.onRefresh();
  },
};
</script>

<style scoped>
#setting {
  height: 100vh;
  /* background: #f3f3f3; */
  /* padding-top:10vh; */
}
.header {
  height: 10vh;
  line-height: 10vh;
  padding: 0 0.3rem;
}
.header-title {
  color: #fff;
  font-size: 0.4rem;
  font-weight: bold;
  vertical-align: middle;
}
.setting-container {
  /* padding: 0.2rem; */
  /* height: 90vh;
  overflow-y: scroll;
  padding-bottom: 1.2rem; */
  /* width: 90%; */
  padding: 0.3rem;
}
.setting-body {
  background: #ffff;
  /* height: 90vh; */
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin-top: 2vh;
  /* padding: 0.3rem; */
  overflow-y: scroll;
  /* padding-bottom: 1.2rem; */
}
.setting-item {
  height: 1rem;
  line-height: 1rem;
}
.setting-item-title {
  color: #333;
  font-size: 0.3rem;
  text-align: left;
}
.setting-item-value {
  color: #333;
  font-size: 0.3rem;
  text-align: right;
}
.cell-input {
  height: 0.5rem;
  line-height: 0.5rem;
  padding: 0 10px;
  text-align: right;
}
.btn {
  height: 0.8rem;
  text-align: center;
  color: #436ef3;
  background: #ecf0fe;
  line-height: 0.8rem;
  margin-top: 0.4rem;
  font-size: 0.3rem;
  border-radius: 5px;
}
</style>
